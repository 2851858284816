var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'box  flex flex-cross--center flex-main--left h100',
    _setup.props.uniqueID === _setup.part.partUniqueId ? 'active' : '',
    _setup.props.border ? 'table-item-line' : '',
  ],style:({
    textAlign: _setup.props.align,
    lineHeight: _setup.props.singleItemHeight + 'px',
  })},[(_setup.part.partUniqueId === _setup.props.uniqueID)?_c('div',{staticClass:"after"},[_vm._t("after",null,{"part":_setup.part,"plank":_setup.part})],2):_vm._e(),_vm._l((_vm.tableHeadProp),function(field,idx){return _c('div',{key:field.prop,class:[
      'part-field ellipsis h100 ',
      field.prop,
      idx === _vm.tableHeadProp.length - 1 ? 'fillBox' : '',
    ],style:({ width: field.width, minWidth: field.minWidth }),on:{"click":function($event){return _setup.dealPartClick(field)}}},[_vm._t(field.prop,function(){return [(field.interactionType)?[(
            _setup.props.field === field.prop &&
            _setup.part.partUniqueId === _setup.props.uniqueID &&
            _setup.deleteHolesSlotsDataList.includes(_setup.props.field)
          )?_c('a-popover',{attrs:{"trigger":"click","placement":"right"},model:{value:(_setup.popVisible),callback:function ($$v) {_setup.popVisible=$$v},expression:"popVisible"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('a-icon',{staticStyle:{"color":"#faad14"},attrs:{"type":"question-circle"}}),_c('span',[_vm._v(" 注意 ：修改后将自动清除孔槽和异形")])],1),_c(_setup.Input,{ref:"inputRef",refInFor:true,attrs:{"value":_setup.inputValue,"id":`${_setup.props.preffixID}material_virtual_list_${_setup.props.rowIndex}_${field.prop}_input`,"size":"small"},on:{"blur":function($event){return _setup.inputBlur(field, $event)},"input":function($event){return _setup.inputChange(field, $event)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _setup.updataBlur($event)}}})],1):(
            _setup.props.field === field.prop && _setup.part.partUniqueId === _setup.props.uniqueID
          )?_c(_setup.Input,{ref:"inputRef",refInFor:true,attrs:{"value":_setup.inputValue,"size":"small","id":`${_setup.props.preffixID}material_virtual_list_${_setup.props.rowIndex}_${field.prop}_input`},on:{"blur":function($event){return _setup.inputBlur(field, $event)},"input":function($event){return _setup.inputChange(field, $event)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _setup.updataBlur($event)}}}):(field.tooltip)?_c(_setup.Tooltip,{attrs:{"content":`${_setup.part[field.prop]}`,"placement":"bottom"}},[_c('span',{staticClass:"ellipsis inline-block w100",attrs:{"id":`${_setup.props.preffixID}material_virtual_list_${_setup.props.rowIndex}_${field.prop}`}},[_vm._v(_vm._s(field.prop === 'partName' ? _setup.showPartName(_setup.part) : _setup.part[field.prop])+" ")])]):_c('span',{attrs:{"id":`${_setup.props.preffixID}material_virtual_list_${_setup.props.rowIndex}_${field.prop}`}},[_vm._v(_vm._s(_setup.dealPropText(_setup.part, field.prop)))])]:(field.tooltip)?_c(_setup.Tooltip,{attrs:{"content":`${_setup.part[field.prop]}`,"placement":"bottom"}},[_c('span',{staticClass:"ellipsis inline-block w100",attrs:{"id":`${_setup.props.preffixID}material_virtual_list_${_setup.props.rowIndex}_${field.prop}`}},[_vm._v(_vm._s(_setup.part[field.prop])+" ")])]):(field.prop === 'select')?_c('a-checkbox',{attrs:{"id":`${_setup.props.preffixID}material_virtual_list_${_setup.props.rowIndex}_choose`},on:{"change":_setup.changeSelect},model:{value:(_setup.part[field.selectProp ?? field.prop]),callback:function ($$v) {_vm.$set(_setup.part, field.selectProp ?? field.prop, $$v)},expression:"part[field.selectProp ?? field.prop]"}}):_c('span',{attrs:{"id":`${_setup.props.preffixID}material_virtual_list_${_setup.props.rowIndex}_${field.prop}`}},[_vm._v(_vm._s(_setup.part[field.prop]))])]},{"part":_setup.part,"plank":_setup.part})],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }